import './main.css';
import * as THREE from 'three';
import React, { useEffect, useRef, useState } from 'react';

const data = Array.from({ length: 77 }, (_, index) => ({
  name: `https://dyp2w7eae4mwj.cloudfront.net/sami_v2/image-${index + 3}.jpg`
}));


const descriptions = [
  ["Top of the Campanille", "We randomly just came here with Aalok and Nandhini and looked at the views outside"],
  ["Da Big Subrise", "This day was hella funny it was also when Aalok suprised Nandhini and she shushed him. This was like the first time I wore this shirt and the first time I wore YOUR vans hoodie"],
  ["Last Years Valentines", "We facetimed last valentines day and I remember you wore that fuzzy pink top that was nice"],
  ["AAA Call", "We trekked down to that little cafe and ate that busting egg bread while u got the french toast like a fatty. Then I had to run and jump start the ford because the center console was running all night while I was at ur dorm"],
  ["The Thinker", "I rememeber you were hella excited to show me that boat ride in SF that was 5 dollars. Then we went to that fancy ass restaurant and got that weird ass white people pizza that was kinda weird tasting but also good. Then I remember making fun of you and tripping while talking a picture later and capturing that. Just remembered the place was called Tiburon."],
  ["Ocean Beach", "Going to that safeway and getting firewood to throw it in that stone firepit at the beach. I rememeber something about being near another couple and it felt like we were fourth wheeling them. The sunset was really nice there you PMOOOO"],
  ["Hooomeeroooom", "I got that weird ass buffalo mac and cheese and you got ur little vegetarian one. I remember the mac and cheese was a little middington but the experience was nice because you were there. This is one of my favorite pictures of you that you absolutely hate. This is the picture I show people if I am showing them who you are"],
  ["Quaint Lil SJ", "Bro this mexican restaurant here was a little pricey but I remember it was gas. It was hella creative and it tasted good. We went and got this coffee ice cream cuz ur a coffee fiend and then we explored those little stalls. We went to that keyboard one and pressed all the keys and went to that pet one and I got the rabbits foot that I fed Max"],
  ["GBBASH", "This was the crazy first day where we were playing those stupid games like charades but with people we know and smash bros. Fun trip"],
  ["Outlets Kapi Haul", "I rememeber this was over the summer when I was at Varian and I was supposed to be \"working from home\". We always end up walking in and out of H&M trying to get a suit for you. I don't think we've ever gone in that store and ended up getting something"],
  ["Zipping through Pleasanton", "This was the day that we both got that brown bracelet together than fell off me almost immediately. I also remember going on those rides which were suprisingly more intense than they shouldve been like that swingy ride that throws you down basically. I also remember getting tigers blood shaved ice cuz u wanted it hella badly like a fattisimo"],
  ["Hot Cheeto", "You finally coming back from your trip in our senior year summer and me giving you that infamous pillow. I went with adit to the night market and looked at the insanely overpriced stussy and food. This was probably one of the best purchases that I couldv'e made and definately do not regret skipping the stussy"],
  ["Post Feast", "After foodie land, we just took a chiller stop at the top of grizzley peak. You gave me the max lappathi shirt here and I remember wearing it alongide the homer chain THAT YOU BROKE BTW. That was a fire ass gift tho the shirt was hella toughy."],
  ["Big Apple", "After coming out of that perfume store with all the overpriced scents and shit, we took this picture on 5th street since it was hella aesthetic. We walked ~1000 miles before this all around the city but it was definately worth it cuz we got almost the entire main city in the day. I was wearing that bummy headstarter shirt while u were wearing that nice dress"],
  ["Fashionista", "Sassy lil pose. pre entering that 9/11 mall. Then i wanted to fat out on some acai bowl so we went to that little store and got that nutella acai which was hella gas i still rememebr it"],
  ["MatchaEn", "Bro is so joyous. We got your matcha and yuzu or whatever that lemon thing was called drink. I remember you weren't very fond of it but atleast we got that POOP matcha souffle too. 0/10 we shouldve gotten that thai tea float but theres always a next time in champaign"],
  ["Amma Mater", "Repping the Illinois to the max like you are a real chicagoan like me. Before this picture we got the full grout fit while getting that busting Paris Super ice cream crepe thing as a sweet treat. After this picture we lollygagged through the main quad to see ourselves in all of the uiuc cameras"],
  ["GO BEARS", "After giving you fomo for making you miss your big game, I gave you the most fire thai food (that you actually paid for lol). But I did get you the free thai tea with the bear on it so I basically paid for the food. GO BEARRRSS"],
  [":O", "After blessing you with trying A2B, Sami Tripasuri is shocked and how fire the food is and how Anirud Lappathi is always right in all of opinions because she thought the food was so good and amazing. I also gave you the chalna parota which was fire as always"],
  ["Near Death Experience", "After taking some flicks at the hayward bay, I touched an extremely deadly poison hemlock. Luckily, I am super smart and shit and hella handsome so the hella poisonous plant didnt affeect me. I learned after that experience to never touch random ass plants"],
  ["Meena Lappathi", "\"TAKE A PICTURE OF MY FIT BEFORE I DONT WANNA LOOK LIKE A BUM INFRONT OF YOUR MOM\", the absolutely patootie gorgeous girl said. I came into the restaurant iwth a hoodie and jeans to make sure I didn't out do you and I made sure to get desert so you don't look like as much of a fatty ocmpared to me. I am the best wingman"],
  ["Sambatman", "Sampootie playing batman on the vr punching the air and shit. Also played that monkey game where you move around with your arms but you needed help getting around."],
  ["Dinoyuta", "This bing su was so good I was so fat this day I was deadly full after thai food and I somehow managed to fit this entire thing in my mouth hole. God gives his hardest battles to his toughest soldiers"],
  ["Horoscopes", "Hitting the half-price books post vegan sushi place like a true berkeley couple. Read a little horoscopes and learned that your weakness is being TO INTENSE and being a bum. Learned my weakness is being too perfect and a brown woman named Samyutie"],
  ["Fat Alert", "I realized all these pictures are always just us being fat and eatings. This funnel cake was hella buss tho was definately worth the gaining 10 pounds."],
  ["Oracle", "Future president sami watches over her future SF pawns as she schemes to take over the entire bay area. One pre-law fraternaty at a time..."],
  ["Art Enthusiast", "Just a calm picture of Sami admiring the fine composition of this thoughtful art piece that probably took years to think of and create. This day we learned that art definately our thing and not pretentious at all"],
  ["AAAAAAAAA", "AAAAAAAHHHHHHHHHHHHHHHHHHHH"],
  ["Party Bus", "Packed prom bus heading to the california academy of sciences or whatever it was called. Top sami pictures came from this day with the fire black dress with the pink accents. Even though actual prom night was so boring, the bus was fun with you"],
  ["Curious George", "We went on top of the watertower hill in the neighborhood with the pool with Aalok and Nandhini hella randomly. You had to wear the fluffy pink nikes and you wore your curious george shirt to bring our your true colors. The digi cam flicks were fire on the hill tho"],
  ["Mount Diablo Snow", "Idk what happend this day but the background was so perfect this day. The green hills with the snow near the crest of mount diablo was so nice at sunset. This spot was like the perfect angle to be at for these pictures and you looking this good is a +1000. We came here pre-rebeccas and on the way back, you fell asleep on my shoulder while we were stuck in traffic"],
  ["Stats Sesh", "Hitting the BIG SR LIBRARY for some intensive AP STATS study session. I remember learning soooooo much this day like I don't think I've been more productive with you. Everytime I am with you at the library I just know I can immediately lock in and get all my work done"],
  ["Art Enthusiast 2", "Just another samyutie looking intensly at the lucious art pieces at the draegers at Blackhawk. Right after this image, we walked outside near the ducks and you refused to go near them"],
  ["Big AHH Bear", "I will never forget how nonchalantly you texted that you got into berkeley that I thought you were making a stupid joke. Then you sent the iamge and I was like wtf that is insane. If you think about it, that moment was a split in the decision tree of your life and you would've been a completely different person if not for that exact moment"],
  ["Beach w/ No Beach", "The time we went to half moon bay but we actually didn't go to half moon bay cuz I missed the exit but we also did go to that sick cliff and ate our bahn mi on the edge. I will never forget this day when you venmo'd me money for the bahn mi's and then i just sent you the money back on venmo writing [REFUNDED] or something like that and you believed it. "],
  ["Sanfrandisco", "Flower jacket, stussy beanie, swag pose, san francisco. BABG material right here walking the streets of san francisco. We went to japan town into those little shops and shit to loko around with Jonathan and Leia and ALSO I remember going to that little japanese tea store with the expensive little tea pots"],
  ["Walnut Creek", "Looking at this picture made me forget that YOUUUU LOST ME BALENCIAGA BAT GOGGLES WTF??? But its ok I forgive you... We saw that one dog treat truck in the market and they were selling weed for dogs and I wanted to get it but it was like 50 dollars."],
  ["Baddie", "This was another picture that I really liked that you didn't see what I liked about it. You look like a baddie in this fit tho I don't get what you don't see with the model pose and shit"],
  ["Santa Cruz", "I'm not gonna lie after swinging you i went home and my back was hurting cuz I twisted it wrong with you on my back like that. These pictures were very memorable tho so it was worth it fs. Me and Trisha didn't plan to go in the water and we still ended up going which was hella fun good thing we did go in the water. MEMORY JUST CAME IN !!! We stopped on the highway for like 5 minute since my check transmission light or something and a cop was looking to turn around to check on us but I left as soon as I saw him turning. Then we went to BJ's and said it was Abhi's bday so we got free pazookies"],
  ["Fooooodie Landdd", "This was a fire event we went to. This was the first time I had pure bonemarrow and it was really good. We also had some honeycomb which was not at all what I expected but that was hella good. I don't really remember what else we got but i just remember it was all pricy but expensive. SIKE I just remembered that we got hot cheeto elotes which honestly was hella mid but we got that made up with that mexican restaurant in SJ. Oh man also I lost that Keith Herring hat in the photo which is sad cuz that was my best hat"],
  ["Barbie Girl", "This was one of or THE last time we saw eachother before college. We came to that crepe place with Aalok and Nandhini and got those fire crepes before going to see barbie. I just realized the amount of times we hungout with Aalok and Nandhini. It feels like only recently that Barbenheimer happend but its crazy that more than a year and a half ago."],
  ["Saucylito", "Sausalito adventure where we talked about hinduism in the car on the way there and the different pillars of it. Then we drank our gentrefied coffee like goons (actually just you goon). We went to a giftstore to window shop those expensive ass items but twas cool"],
  ["Ammar's Party", "This was a crazy rager with the most random heads appearing. This entire party feels like a fever dream cuz how did this flick appear, what time period of school was this, and what did I even do that day. IDK but it was fun"],
  ["Teacher Holi", "Going to the auditorium and blasting music before this was hella fun. Those memories were probably mostly from dandiya idk if we did it during this event but I just remember that. I met ur family friends during that lunch. the food was pretty good"],
  ["Mall of India", "the only memories from this was how indian the mall was during this time. I remember SF being so kinda diverse before and it felt like only indians on this day. We went to the LV store and the indian people cut down but inside the mall was deadly"],
  ["Cultural Night", "This night was hella fun because I convinced them to let me wear the bear suit. I have no idea why they gave me the bear suit cuz there was no reason for me to get it but it was weird just walking around pretending to be a mascot to the people and kids and shit. This was also the day where we took that photo station thing together which my mom ended up finding in my desk"],
  ["Haul Try On", "After going to stoneridge to find the perfect puffer for u, this is the goon fit you ended up with."],
  ["ROOH SF", "The dish in this video honestly was so mid cuz it was just like pure beans but it the pani puri thing was hella good and differnt cuz the yogurt was whipped. The pinwheel paneer was so fire too i ended up taking my parents here too because of this experience. Since the bill was so high, I had to make a fake large group that went and that I paid their bills just so it wasn't sus"],
  ["UIUC Mirror", "This was like the 50th mirror picture from UIUC but I think this one was pre sakinaya where you got the OMG sushi order. I also got the fighting illini hat at the gift shop earlier that day. Sakinaya is forever the best sushi its so good and relatively cheap and a nice ass restaurant."],
  ["Pleasanton Downtown", "This is the first time (pretty sure) we came to this restaurant since I remember going with Nandhini and Aalok another time end of freshman year college summer which was when I got the PwC offer. I remember this crepe was really good tho the inside whipped cream stuff was good. Wearing the stoneridge haul puffer and such"],
  ["Unit 3 Sesh", "This is the most calm picture of us. I'm just looking at the comptuer doing stuff and ur looking there too so calm. You can see the early laptop sticker progression since you can actually see the clear cover"],
  ["Suprise BDAY", "When I walked in, I genuinely didn't expect this to be a suprise at all. Even when walking into the house I didn't suspect anything with you standing behind me recording. The suprise and the pre-party was so nice with all of us just chilling and when the party hit it was so hectic but that was definately a memorable day. idk why I am mogging so hard in the picture"],
  ["Jurassic Grill", "My stomach hurt so bad from giggling this day I have no idea why I was giggling but this was such a happy day out. The burger video was peak goon and u meeting my uiuc friends was hella fun too"],
  ["Foot Chair", "We had deep ass conversations with Aalok and Nandhini this day since tanvi khushboos grad party was so segregated. I remember making up \"pizza\" and the scratch neck thing for silent communication esk signals. THen we ended up making a foot chair which actually worked"],
  ["Viks Chaat", "This was pretty recent but u got ur famous chole bhatura and I got my famous dahi puri so we could relive our first date. I remember just cheifing all of ur chole and also eating all of my dahi puri because I needed to make sure you diet. Then we got rasamalai which was so gas it's always top 1 indian desert"],
  ["Colt Tower", "Even though we didn't even go into the actual colt tower, the parking lot view was good enough and the view of the streets were pretty cool with the colorful buildings on the hills. I look like a chipmunk in this picture"],
  ["Jonathan's Party", "These digi flicks were all so tough and this party was pretty fire. I have no idea why I was repping the Chalkaa here but this was post bollywood movie night two where we were chilling in the library watching some movie that I forgot."],
  ["Target Sunglasses", "This day was so goon, we went to city center to take pictures of the tree lighting ceremony with you me and tanvi and we also saw my dad randomly just watching the tree lighting too so I had to pretend I was there by myself. Then we went to target and crumbl to goon around"],
  ["The Beginning", "This was the first date every with us starting at grizzley peak, coming to viks chaat, then going to the closed emeryville mall all while being like our first conversations where we were thanging"],
  ["Alpaca Scarf", "Before your cruise over winter break last year, me and ishan pulled up to your house and literally just talked for like two hours outside your house at ur front door, in your living room, and in your backyard"],
  ["Graduation", "My grad cap was 0 effort but it still worked. We were so decked out on those accalades and what not cuz the news paper and the orange cal high one and everything and you got everything everywhere I have no idea what you have. After graduation we had the grad party where I was wearing that hella professional fit and we were just walking around for like 4 hours doing nothing"],
  ["Pre Wingstop CVS Run", "I have no idea why we were in CVS then but I remember this fit being the day we tried wing stop fries at the preserve neighborhood pool. Those fries were hella busting back then and this was like near when we first started dating so it was pretty monumental fries moment. You were walking like a tired ol lady in this picture"],
  ["Alameda Pho", "Your first time getting pho and our first destination type date. I feel like this date was one of our most important dates because we went and explored and everything. The weather this day was so gloomy and rainy but the day was still so nice. I liked this jacket that you had but I think you lost it like a bum. I made you eat the jalapeno and I didn't even eat it cuz I didn't wanna look like I was dying during our dinner. I PYOOOOO PHOOO"],
  ["Del Valle", "This was taken on mines road when we were coming back from Del Valle after looking at that dried up lake. We went to micheals and bought the materials to make bracelets with eachothers names on it and we still have never made it"],
  ["DERRRRRRRR", "DERRRRRRRRRRRRRRRR"],
  ["Audio Engineer", "No idea what the context behind this picture was but you were trying on headphones and I took this picture and i remember you looked at it and told me to delete it. I did not delete it....."],
  ["Berkeley Pizza", "This pizza place was really good I almost forgot about it. We got some weird ass beer cheese which was ass ngl BUTTTT the pizza was really good even though it was also like a weird flavor. The backyard seating was a nice view tho and looked very aesthetic"],
  ["POFAAAA", "This was near or maybe even the first time we went to SF. This day we did like all the touristy things in SF to make sure we get our tourist phase out of the way. You always used to wear this fuzzy jacket back way then"],
  ["Holi", "Lowkey even though money wise you flopped this event because you didn't have my planning, it was fun since we got to throw hella color. The star chaat was also the thing that put me on papdi chaat. This was the day that caused my venmo history of papdi chaat. NGL you looked so scary in this picture but the day was fun"],
  ["Windy SF", "We parked at this beach and fought the wind demons to get this picture. It was actually hard to walk and see because the wind was blasting sand in our face but we got da flick."],
  ["Cookie Decorating", "I DESTROYED YOU GUYS IN FROSTING COOKIES. I was just being humble then but YOU ALL SUCK AT IT IM SO GOOD AT DECORATING COOKIES LOOK HOW FIRE MINE IS. Just wanted to let you know btw."],
  ["First Incontro", "This was our first incontro visit and was where we dressed hella fancy. This is still probably my top italian restaurant because the food is so good. I always test new things on the menu but I think I should be passed that phase so I can now just get the fettucini cuz I know its the best pasta. You got the fettucini cuz you know whats the best. This dress is probably my favotite of yours alongside the blue one with flowers that you wore for Rooh SF"],
  ["Panera Bread", "NGL i don't even remember why we were in Panera bread but I think we might have been studying for AP Stats or something because panera bread was always the study destination. This was back in senior year when everyone would study in panera"],
  ["Freshman Springbreak", "This was the first day that I saw you in spring break. You should be thankful that I have a drivers license because I had to drive like 200 time back and forth to see you that break. Honestly all I remember from spring break is driving. I like this pink top."],
  ["Fob Sami", "No idea where this photo is from but you are just an indian baddie"],
  ["Bollywood Movie Night 2", "This second bollywood movie night was not as memorable as the first cuz I don't even know what movie was playing but these library floor seats are the reason I got floor seats for you and me and this was memorable because this was pre Jonathan's party"],
  ["Washington DC Golden Hour", "Scary ass picture but I just wanted to add it since youre eyes look so big and cute. You were sleeping on the floor or something in DC for your internship and I was at home gooning for my internship"],
]

const params = {
  rows: 9,
  columns: 8,
  curvature: 5,
  spacing: 5,
  imageWidth: 7/1.5,
  imageHeight: 4.5/1.5,
  depth: 7.5,
  elevation: 0,
  lookAtRange: 20,
  verticalCurvature: 0.5,
};

export const ValentinesTwo = () => {
  const [loaded, setLoaded] = useState(false);
  const [description, setDescription] = useState(null);
  const headerRef = useRef(null);

  const loadedRef = useRef(loaded);
  useEffect(() => {
    loadedRef.current = loaded;
  }, [loaded]);

  useEffect(() => {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      25,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    camera.position.set(0, 0, 40);

    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setClearColor(0x000000);
    renderer.domElement.style.display = "none";
    document.body.appendChild(renderer.domElement);

    let headerRotationX = 0;
    let headerRotationY = 0;
    let headerTranslateZ = 0;
    let mouseX = 0;
    let mouseY = 0;
    let targetX = 0;
    let targetY = 0;
    const lookAtTarget = new THREE.Vector3(0, 0, 0);

    const loadingManager = new THREE.LoadingManager(() => {
      setLoaded(true);
      renderer.domElement.style.display = "block";
      if (headerRef.current) {
        headerRef.current.style.display = "none";
      }
    });

    function createImageTexture(imageSource) {
      return new THREE.TextureLoader(loadingManager).load(imageSource);
    }

    function calculateRotations(x, y) {
      const a = 1 / (params.depth * params.curvature);
      const slopeY = -2 * a * x;
      const rotationY = Math.atan(slopeY);

      const verticalFactor = params.verticalCurvature;
      const maxYDistance = (params.rows * params.spacing) / 2;
      const normalizedY = y / maxYDistance;
      const rotationX = normalizedY * verticalFactor;

      return { rotationX, rotationY };
    }

    function calculatePosition(row, col) {
      let x = (col - params.columns / 2) * params.spacing;
      let y = (row - params.rows / 2) * params.spacing;
      let z = (x * x) / (params.depth * params.curvature);

      const normalizedY = y / ((params.rows * params.spacing) / 2);
      z += Math.abs(normalizedY) * normalizedY * params.verticalCurvature * 5;
      y += params.elevation;
      const { rotationX, rotationY } = calculateRotations(x, y);
      return { x, y, z, rotationX, rotationY };
    }

    let images = [];

    function createImagePlane(row, col, imageID) {
      if (imageID >= data.length) {
        return;
      }
      const imageData = data[imageID];
      const texture = createImageTexture(imageData.name);

      const geometry = new THREE.PlaneGeometry(
        params.imageWidth,
        params.imageHeight
      );
      const material = new THREE.MeshBasicMaterial({
        map: texture,
        side: THREE.DoubleSide,
      });

      const plane = new THREE.Mesh(geometry, material);
      const { x, y, z, rotationX, rotationY } = calculatePosition(row, col);
      plane.position.set(x, y, z);
      plane.rotation.x = rotationX;
      plane.rotation.y = rotationY;
      plane.userData.basePosition = { x, y, z };
      plane.userData.baseRotation = { x: rotationX, y: rotationY, z: 0 };
      plane.userData.parallaxFactor = Math.random() * 0.5 + 0.5;
      plane.userData.randomOffset = {
        x: Math.random() * 2 - 1,
        y: Math.random() * 2 - 1,
        z: Math.random() * 2 - 1,
      };
      plane.userData.rotationModifier = {
        x: Math.random() * 0.15 - 0.075,
        y: Math.random() * 0.15 - 0.075,
        z: Math.random() * 0.2 - 0.1,
      };
      plane.userData.phaseOffset = Math.random() * Math.PI * 2;
      plane.userData.description = descriptions[imageID];
      plane.userData.selected = false;

      return plane;
    }

    function updateGallery() {
      images.forEach((plane) => scene.remove(plane));
      images = [];
      let imageIDs = [...Array(descriptions.length).keys()];
      let imageID = 0;
      for (let row = 0; row < params.rows; row++) {
        for (let col = 0; col < params.columns; col++) {
          const plane = createImagePlane(row, col, imageIDs[imageID]);
          imageID += 1;
          images.push(plane);
        }
      }
      images.forEach((plane) => scene.add(plane));
    }

    document.addEventListener("mousemove", (event) => {
      mouseX =
        (event.clientX - window.innerWidth / 2) / (window.innerWidth / 2);
      mouseY =
        (event.clientY - window.innerHeight / 2) / (window.innerHeight / 2);
      headerRotationX = -mouseY * 30;
      headerRotationY = mouseX * 30;
      headerTranslateZ = Math.abs(mouseX * mouseY) * 50;
    });

    window.addEventListener("resize", () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    });

    const onDocumentClick = (event) => {
      if (!loadedRef.current) return;
      const mouse = new THREE.Vector2(
        (event.clientX / window.innerWidth) * 2 - 1,
        -(event.clientY / window.innerHeight) * 2 + 1
      );
      const raycaster = new THREE.Raycaster();
      raycaster.setFromCamera(mouse, camera);
      const intersects = raycaster.intersectObjects(images);
      if (intersects.length > 0) {
        const clickedObject = intersects[0].object;
        if (clickedObject.userData.selected) {
          images.forEach((plane) => (plane.userData.selected = false));
          setDescription(null);
        } else {
          images.forEach(
            (plane) => (plane.userData.selected = plane === clickedObject)
          );
          setDescription(clickedObject.userData.description);
        }
      } else {
        images.forEach((plane) => (plane.userData.selected = false));
        setDescription(null);
      }
    };

    const onDocumentHover = (event) => {
      if (!loadedRef.current) return;
      const mouse = new THREE.Vector2(
        (event.clientX / window.innerWidth) * 2 - 1,
        -(event.clientY / window.innerHeight) * 2 + 1
      );
      const raycaster = new THREE.Raycaster();
      raycaster.setFromCamera(mouse, camera);
      
      if (images.length === 0) return;
      const intersects = raycaster.intersectObjects(images);
      if (intersects.length > 0) {
        document.body.style.cursor = "pointer";
      } else {
        document.body.style.cursor = "default";
      }
    };
    
    document.addEventListener("mousemove", onDocumentHover);
    
    document.addEventListener("click", onDocumentClick);

    function animate() {
      requestAnimationFrame(animate);

      if (headerRef.current) {
        const targetTransform = `
          translate(-50%, -50%)
          perspective(1000px)
          rotateX(${headerRotationX}deg)
          rotateY(${headerRotationY}deg)
          translateZ(${headerTranslateZ}px)
        `;
        headerRef.current.style.transform = targetTransform;
        headerRef.current.style.transition =
          "transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1)";
      }

      targetX += (mouseX - targetX) * 0.05;
      targetY += (mouseY - targetY) * 0.05;
      lookAtTarget.x = targetX * params.lookAtRange;
      lookAtTarget.y = -targetY * params.lookAtRange;
      lookAtTarget.z =
        (lookAtTarget.x * lookAtTarget.x) / (params.depth * params.curvature);

      images.forEach((plane) => {
        if (plane.userData.selected) {
          const targetPos = new THREE.Vector3();
          camera.getWorldDirection(targetPos);
          targetPos.normalize().multiplyScalar(20).add(camera.position);
          plane.position.lerp(targetPos, 0.1);
          plane.quaternion.slerp(camera.quaternion, 0.1);
        } else {
          const {
            basePosition,
            baseRotation,
            parallaxFactor,
            randomOffset,
            rotationModifier,
            phaseOffset,
          } = plane.userData;
          const mouseDistance = Math.sqrt(targetX * targetX + targetY * targetY);
          const parallaxX = targetX * parallaxFactor * 3 * randomOffset.x;
          const parallaxY = targetY * parallaxFactor * 3 * randomOffset.y;
          const oscillation = Math.sin(phaseOffset) * mouseDistance * 0.1;
          const targetPos = new THREE.Vector3(
            basePosition.x + parallaxX + oscillation * randomOffset.x,
            basePosition.y + parallaxY + oscillation * randomOffset.y,
            basePosition.z + oscillation * randomOffset.z * parallaxFactor
          );
          plane.position.lerp(targetPos, 0.1);

          const targetEuler = new THREE.Euler(
            baseRotation.x +
              targetY * rotationModifier.x * mouseDistance +
              oscillation * rotationModifier.x * 0.2,
            baseRotation.y +
              targetX * rotationModifier.y * mouseDistance +
              oscillation * rotationModifier.y * 0.2,
            baseRotation.z +
              targetX * targetY * rotationModifier.z * 2 +
              oscillation * rotationModifier.z * 0.3
          );
          const targetQuaternion = new THREE.Quaternion().setFromEuler(
            targetEuler
          );
          plane.quaternion.slerp(targetQuaternion, 0.1);
        }
      });

      camera.lookAt(lookAtTarget);
      renderer.render(scene, camera);
    }

    updateGallery();
    animate();

    return () => {
      document.removeEventListener("click", onDocumentClick);
      document.body.removeChild(renderer.domElement);
    };
  }, []);

  return (
    <div className="valentines-two-page">
      <nav>
        {description && (
          <>
            <p>{description[0]}</p>
            <p>{description[1]}</p>
          </>
        )}
      </nav>
      {!loaded && (
        <div className="header" ref={headerRef}>
          <h1>WILL YOU BE MY VALENTINE SAMI?</h1>
        </div>
      )}
    </div>
  );
};

export default ValentinesTwo;