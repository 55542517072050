import "../index.css";
import Github from "../assets/github";
import LinkedIn from "../assets/linkedin";
import { Link as LinkScroll } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";
import ResumeButton from "../ResumeButton";

function Footer() {
  return (
    <div className="w-screen lg:h-36 h-36 bg-black py-10 md:px-36 px-12">
      <div className="flex items-align justify-between">
        <div className="transition-transform text-white">
          <LinkScroll
            to="header"
            smooth={true}
            duration={500}
            className="lg:text-md text-sm font-jetbrains-regular select-none hover:cursor-pointer">
            anirudlappathi.com
          </LinkScroll>
          <div className="grid grid-col-3 gap-3">
            <div className="flex gap-4">
              <a
                draggable="false"
                href="https://www.linkedin.com/in/anirudl/"
                target="_blank"
                rel="noreferrer">
                <LinkedIn />
              </a>
              <a
                draggable="false"
                href="https://github.com/anirudlappathi"
                target="_blank"
                rel="noreferrer">
                <Github />
              </a>
            </div>
          </div>
        </div>
        <div className="text-white text-center lg:text-sm text-xs font-jetbrains-regular select-none">
          built with react / tailwind / three.js
        </div>
        <div>
          <ResumeButton onWhite={false} />
          <div> 
          {/* <RouterLink
            to="/music"
            className="p-0 m-0 "
          >◾</RouterLink> */}
          <RouterLink
            to="/valentines2024"
            className="p-0 m-0 "
          >◾</RouterLink>
          <RouterLink
            to="/valentines2025"
            className="p-0 m-0 "
          >◾</RouterLink>
          {/* <RouterLink
            to="/blog"
            className="p-0 m-0 "
          >◾</RouterLink> */}
        </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
