import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import FrontPage from "./frontpage/Frontpage";
import MusicPage from "./music/Musicpage";
import ResumeFull from "./resume/Resume";
import Research from "./research/Research";
import ValentinesDay from "./valentines/ValentinesFrontpage";
import Blog from "./blog/BlogFrontpage";
import BlogSpotifyLocalFiles from "./blog/content/SpotifyLocalFiles";
import ValentinesTwo from "./valentines2/Main";
import PageNotFound from "./PageNotFound";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<FrontPage />} />
        <Route path="/music" element={<MusicPage />} />
        <Route path="/resume" element={<ResumeFull />} />
        <Route path="/research" element={<Research />} />
        <Route path="/valentines2024" element={<ValentinesDay />} />
        <Route path="/valentines2025" element={<ValentinesTwo />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/spotify-local-files" element={<BlogSpotifyLocalFiles />} />
        <Route path="*" element={<PageNotFound/>} />
      </Routes>
    </Router>
  );
};

export default App;
